<template>
  <div>
    <v-card
      v-if="rated && rated.canRate"
      flat
      >
      <v-divider></v-divider>

      <v-card-title
        class="font-weight-regular text-subtitle-2 pb-1"
        >
        Danos tu opinión
      </v-card-title>
      <v-card-text>
        <v-rating
          background-color="grey lighten-2"
          color="primary"
          empty-icon="mdi-star-outline"
          full-icon="mdi-star"
          hover
          length="5"
          v-model="rate.rate"
          class="mb-3"
          ></v-rating>

        <v-textarea
          outlined
          label="Comentario"
          v-model="rate.comment"
          hide-details
          >
        </v-textarea>

        <div
          class="text-right pt-3"
          >
          <v-btn
            :disabled="!rate.rate || loading"
            :loading="loading"
            small
            color="primary"
            @click="create"
            >
            Enviar
          </v-btn>
        </div>
      </v-card-text>
    </v-card>

    <div
      v-else
      >
      <v-card
        v-if="rated && rated.rate"
        flat
        >
        <v-divider></v-divider>

        <v-card-title
          class="font-weight-regular text-subtitle-2 pb-1"
          >
          Tu opinión
        </v-card-title>

        <v-card-text>
          <v-alert
            color="grey lighten-1"
            class="mb-0"
            outlined
            >
            <div
              class="caption px-2 grey--text text--darken-1"
              >
              {{ (new Date(rated.rate.createdAt)).toLocaleDateString() }}
            </div>
            <v-rating
              background-color="grey lighten-2"
              color="primary"
              empty-icon="mdi-star-outline"
              full-icon="mdi-star"
              length="5"
              readonly
              v-model="rated.rate.rate"
              size="15"
              class="mb-3"
              ></v-rating>
            <div
              class="px-2 primary--text text--lighten-2"
              >
              {{ rated.rate.comment }}
            </div>
          </v-alert>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { Rates, IsUserRated } from '@/graphql/queries/rates'
import { CreateRate } from '@/graphql/mutations/rate' 

export default {
  props: {
    product: {
      required: true,
      type: Object
    }
  },

  data: () => {
    return {
      loading: false,
      rate: {
        rate: null,
        comment: null,
        rateableType: 'Product',
        rateableId: null
      },
      rated: null,
    }
  },

  mounted () {
    this.rate.rateableId = this.product.id
    this.fetchUserRated()
  },

  methods: {
    parse (rate) {
      return parseInt(rate)
    },

    fetchUserRated () {
      this.loading = true
      this.$apollo.query({
        query: IsUserRated,
        variables: {
          rateableType: 'Product',
          id: this.product.id
        },
        fetchPolicy: 'no-cache'
      }).then( res => {
        this.loading = false
        this.rated = res.data.isUserRated
      })
    },

    create () {
      this.loading = true
      this.$apollo.mutate({
        mutation: CreateRate,
        variables: {
          input: {
            attributes: this.rate
          }
        }
      }).then ( res => {
        this.fetchUserRated ()
        this.$emit('reload')
      })
    }
  }
}
</script>
